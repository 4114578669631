<template>
  <div class="elite-tabs-wrapper-content">
    <div class="title-header">
      <div class="row">
        <div class="col-6">
          <h3>{{ salesDelivery.code }}</h3>
        </div>

        <div class="col-6 text-right">
          <base-button
            class="btn-outline-danger"
            icon
            size="sm"
            v-if="
              salesDelivery.status === DELIVERY_STATUS_DRAFT &&
              $currentUserCan($permissions.PERM_DELETE_SALES_DELIVERIES)
            "
            @click="deleteSalesDelivery"
          >
            <span class="btn-inner--icon">
              <i class="far fa-trash-alt"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("COMMON.DELETE") }}
            </span>
          </base-button>

          <base-button
            class="elite-button add"
            icon
            size="sm"
            v-if="
              salesDelivery.status === DELIVERY_STATUS_DRAFT &&
              $currentUserCan($permissions.PERM_EDIT_SALES_DELIVERIES)
            "
            @click="editSalesDelivery"
          >
            <span class="btn-inner--icon">
              <i class="far fa-edit"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("COMMON.EDIT") }}
            </span>
          </base-button>
        </div>
      </div>
    </div>

    <div class="all-infos">
      <div class="all-infos-text">
        <dl class="row">
          <dt>{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd>
            {{ salesDelivery.created_at | moment("LLLL") }}
          </dd>
        </dl>

        <dl class="row">
          <dt>
            {{ $t("SALES_DELIVERIES.EXPIRATION_TIME") }}
          </dt>
          <dd>
            {{ salesDelivery.expiration_time | moment("LLLL") }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.LOCATIONS") }}</dt>
          <dd>
            <locations :locations="salesDelivery.allowedLocations" />
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.ORGANIZATION") }}</dt>
          <dd>
            <organization :organization="salesDelivery.organization" />
          </dd>
        </dl>

        <dl class="row" v-if="salesDelivery.salesOrder">
          <dt>{{ $t("COMMON.SALES_ORDER") }}</dt>
          <dd>
            <object-link :object="salesDelivery.salesOrder" />
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.RECIPIENT") }}</dt>
          <dd>
            <object-link :object="salesDelivery.recipient" />
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.STATUS") }}</dt>
          <dd>
            <sales-delivery-status-badge :salesDelivery="salesDelivery" />
          </dd>
        </dl>

        <dl class="row">
          <dt v-if="salesDelivery.excerpt">
            {{ $t("COMMON.NOTE") }}
          </dt>
          <dd v-if="salesDelivery.excerpt">
            <div v-html="salesDelivery.excerpt"></div>
          </dd>
        </dl>

        <div class="col-12 text-center mb-5 mt-5">
          <h2>{{ $t("COMMON.DELIVERY_ADDRESS") }}</h2>
        </div>

        <dl
          class="row"
          v-if="
            salesDelivery.delivery_entity_type ===
            BILLING_ENTITY_TYPE_INDIVIDUAL
          "
        >
          <dt>{{ $t("COMMON.LASTNAME") }}</dt>
          <dd>
            {{ salesDelivery.delivery_lastname }}
          </dd>
        </dl>

        <dl
          class="row"
          v-if="
            salesDelivery.delivery_entity_type ===
            BILLING_ENTITY_TYPE_INDIVIDUAL
          "
        >
          <dt>{{ $t("COMMON.FIRSTNAME") }}</dt>
          <dd>
            {{ salesDelivery.delivery_firstname }}
          </dd>
        </dl>

        <dl
          class="row"
          v-if="
            salesDelivery.delivery_entity_type === BILLING_ENTITY_TYPE_COMPANY
          "
        >
          <dt>{{ $t("COMMON.COMPANY_NAME") }}</dt>
          <dd>
            {{ salesDelivery.delivery_company_name }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.COUNTRY") }}</dt>
          <dd>
            {{ salesDelivery.delivery_country }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.STATE") }}</dt>
          <dd>
            {{ salesDelivery.delivery_state }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.CITY") }}</dt>
          <dd>
            {{ salesDelivery.delivery_city }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.ZIPCODE") }}</dt>
          <dd>
            {{ salesDelivery.delivery_zipcode }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.ADDRESS") }}</dt>
          <dd>
            {{ salesDelivery.delivery_address }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.EMAIL") }}</dt>
          <dd>
            {{ salesDelivery.delivery_email }}
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BILLING_ENTITY_TYPE_INDIVIDUAL,
  BILLING_ENTITY_TYPE_COMPANY,
} from "@/constants/billingInformations";
import SalesDeliveryStatusBadge from "./SalesDeliveryStatusBadge.vue";
import { DELIVERY_STATUS_DRAFT } from "@/constants/deliveries";

export default {
  name: "sales-delivery-view-global",

  components: {
    SalesDeliveryStatusBadge,
  },

  props: ["salesDelivery"],

  data() {
    return {
      BILLING_ENTITY_TYPE_INDIVIDUAL: BILLING_ENTITY_TYPE_INDIVIDUAL,
      BILLING_ENTITY_TYPE_COMPANY: BILLING_ENTITY_TYPE_COMPANY,
      DELIVERY_STATUS_DRAFT,
    };
  },

  computed: {},

  created() {},

  methods: {
    editSalesDelivery() {
      this.$emit("onEditSalesDelivery", this.salesDelivery);
    },
    deleteSalesDelivery() {
      this.$emit("onDeleteSalesDelivery", this.salesDelivery);
    },
  },

  mounted() {},

  watch: {
    salesDelivery(salesDelivery) {},
  },
};
</script>
